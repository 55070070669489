<template>
  <div class="right_b_left">
    <div class="right_b_left_left">
      <!-- 姓名 -->
      <div id="name" class="right_b_left1" @click="seepersonal(author.id)">{{author.name}}</div>
      <!-- 专业 -->
      <div id="major" class="right_b_left1">{{author.industry}}</div>
      <!-- 文章数 -->
      <!-- <div id="Article" class="right_b_left1">
        {{author.beCommentedNum}} 文章 -->
        <!-- 关注 -->
        <!-- <span class="follow">{{author.concernNum}}关注</span>
      </div> -->
    </div>
    <!-- 图片显示 -->
    <div class="right_b_left_right">
      <div id="url" class="right_b_right1">
        <el-image
          :src="author.img"
          @click="seepersonal(author.id)"
          style="border-radius: 50%;width:80px;height:80px;cursor: pointer; box-shadow: 1px 2px 13px 0px rgba(67, 135, 238, 0.44);"
        ></el-image>
        <span class="VVVV"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    author: {
      type: Object,
      required: true
    }
  },
  methods: {
    //个人主页
    async seepersonal(id) {
      this.$router.push({
        path: "/authorXX",
        query: {
          id: id
        }
      });
    }
  }
};
</script>
<style scoped>
.VVVV {
  color: #fda444;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 66px;
  left: 68px;
  display: inline-block;
  width: 18px;
  height: 18px;
  /* opacity: 0.9; */
  background-size: 100% 100%;
  background-image: url("~@/assets/icon/v.png");
}
.right_b_left {
  width: 100%;
  padding: 10px;
  border-top: 1px solid #d2d2d2;
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  /* overflow: hidden; */
  /* border:1px solid rebeccapurple */
}
.right_b_left1 {
  font-size: 10px;
  width: 130px;
  display: inline-block;
  cursor: pointer;
}
#name {
  font-weight: bold;
  font-size: 18px;  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
#name,
#major,
#Article {
  /* margin-bottom: 10px; */
  margin-top: 10px;
  margin-left: 15px;
  display: inline-block;
}
#major,
#Article {
  color: grey;
  font-size: 15px;
}
.right_b_left_left {
  width: 58%;
  /* border: 1px solid yellow; */
  margin-top: 10px;
}
.right_b_left_right {
  width: 30%;
  height: 90px;
  /* padding-left:6%; */
  text-align: center;
  /* margin: auto; */
  display: inline-block;
  /* border: 1px solid red; */
  /* padding-bottom: 5px; */
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.right_b_left_left,
.right_b_left_right {
  float: left;
}
.follow {
  margin-left: 30px;
}
#url {
  width: 90px;
  height: 90px;
  /* border:1px dashed black;s */
  padding: 3px;
  margin: auto;
  text-align: center;
  /* position: absolute; */
  /* margin-left:-(width/2);
margin-top:-(height/2) */
}
</style>