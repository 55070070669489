<template>
  <div class="container">
    <!-- 内容 -->
    <div class="center">
      <div class="content">
        <div class="left">
          <!-- 推荐文章列表 -->
          <div class="list">
            <V-ArticleList :newarticlelist="newarticlelist"></V-ArticleList>
          </div>
          <br />
          <!-- 分页 -->
          <div class="page-nav">
            <el-pagination
              background
              layout="prev, pager, next"
              :hide-on-single-page="hidePageNav"
              :total="totlePage"
              :page-size="pageSize"
              @prev-click="prevPage"
              @next-click="nextPage"
              @current-change="currentPage"
            ></el-pagination>
          </div>
        </div>
        <div class="right-content">
          <!-- 优秀作者 -->
          <div class="right">
            <div id="author">
              <span>知识网红</span>
              <span class="change2" @click="rotateFlash()">
                <img
                  src="~@/assets/icon/change2.png"
                  style="width:20px;  cursor: pointer;"
                  id="flash-bin"
                />
              </span>
            </div>
            <div style="width:102%;" class="right_b" v-for="item in authors" :key="item.id">
              <V-author :author="item.author"></V-author>
            </div>
            <!-- <div id="author">优秀作者</div> -->
            <!-- <div style="width:102%;" class="right_b" v-for="item in authors" :key="item.id">
              <V-author :author="item.author"></V-author>
            </div>-->
            <div>
              <!-- 精选主题 -->
              <!-- <div class="right_c">
                <div id="topic">精选主题</div>
                <div class="right_b_left2" v-for="rArticle in relationArticle" :key="rArticle.id">
                  <V-OneRelationArticle :article="rArticle"></V-OneRelationArticle>
                </div>
              </div>-->

              <!-- 热门关键字   -->
              <!-- <div class="right3"> -->
              <!-- <div class="grid">热门关键字</div> -->
              <!-- <V-HotSearchWord :hotSearchWord="hotSearchWord"></V-HotSearchWord> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HotType from "@/views/academic-headlines/hot-list/hot-type/index.vue";
import ArticleList from "@/components/one-article/type-one/index.vue";
import Autho from "@/views/academic-headlines/recommend/excellent-author/index.vue";
//import OneRelationArticle from '@/components/author/Selected topics/index.vue'
import OneRelationArticle from "@/components/linfo-rpic/index.vue";
import HotSearchWord from "@/components/hot-search-word/index.vue";
import { DELETE, QUERY, QUERYED } from "@/services/dao.js";
import { formatDate, integralAddOne } from "@/js/common";

export default {
  data() {
    return {
      activities: [],
      n: 0,
      pageNum: 50,
      pageSize: 10,
      totlePage: 5000,
      hidePageNav: true,
      newarticlelist: [],
      hotSearchWord: [],
      authors: [],
      relationArticle: []
    };
  },
  created: function() {
    this.init();
  },
  methods: {
    // 最新快讯
    async newslist() {
      let data = await QUERY(
        "post",
        "",
        "PCUsers(where: {celebrity : {_eq: t}},limit:" +
          15 +
          ",offset:" +
          15 * this.n +
          ") {name id industry concernNum beCommentedNum img}  PCUsers_aggregate{    aggregate {      count    }  }"
      );

      // 依次抵消this.n*4条数据，直到最后一条重置this.n=0
      if ((this.n + 1) * 15 < data.data.PCUsers_aggregate.aggregate.count) {
        this.n++;
      } else {
        this.n = 0;
      }
      this.authors.splice(0, this.authors.length);
      for (let i = 0; i < data.data.PCUsers.length; i++) {
        this.authors.push({
          author: {
            id: data.data.PCUsers[i].id,
            name: data.data.PCUsers[i].name,
            industry: data.data.PCUsers[i].industry,
            concernNum: data.data.PCUsers[i].concernNum,
            beCommentedNum: data.data.PCUsers[i].beCommentedNum,
            img: this.api.LoginURL.concat(data.data.PCUsers[i].img)
          }
        });
      }
    },
    // 最新快讯刷新按钮
    rotateFlash() {
      // js控制刷新图标的动画效果
      let flashIcon = document.getElementById("flash-bin");
      if (!flashIcon.classList.contains("flash-bin")) {
        flashIcon.classList.add("flash-bin");
        setTimeout(() => {
          flashIcon.classList.remove("flash-bin");
        }, 550);
      }
      this.newslist();
    },
    init() {
      this.searchInfo(this.pageSize, 0); //显示推荐的文章内容
      this.newslist(); //知识网红
      // this.Excellent_author(); //优秀作者
      // this.JX_theme(); //精选主题
      //获取热门搜索
      // this.getHotSearch();
    },
    ///精选主题
    async JX_theme() {
      this.relationArticle.splice(0, this.relationArticle.length);
      let info = await QUERY(
        "post",
        "",
        "    Theme(limit: 5) {   title   img  id  }"
      );
      for (let i = 0; i < info.data.Theme.length; i++) {
        this.relationArticle.push({
          id: info.data.Theme[i].id,
          info: info.data.Theme[i].title,
          img: this.api.LoginURL.concat(info.data.Theme[i].img)
        });
      }
    },
    ///优秀作者
    async Excellent_author() {
      let data = await QUERY(
        "post",
        "",
        "  PCUsers(limit: 5) {name id industry concernNum beCommentedNum img}"
      );

      this.authors.splice(0, this.authors.length);
      for (let i = 0; i < data.data.PCUsers.length; i++) {
        this.authors.push({
          author: {
            id: data.data.PCUsers[i].id,
            name: data.data.PCUsers[i].name,
            industry: data.data.PCUsers[i].industry,
            concernNum: data.data.PCUsers[i].concernNum,
            beCommentedNum: data.data.PCUsers[i].beCommentedNum,
            img: this.api.LoginURL.concat(data.data.PCUsers[i].img)
          }
        });
        // integralAddOne(this.authors[i].author.id)
        //alert(this.authors[i].author.id)
        // let user =this.$store.state.userinfo.id;
        //
      }
    },
    //获取热门搜索
    async getHotSearch() {
      this.hotSearchWord.splice(0, this.hotSearchWord.length);
      let info = await QUERY(
        "post",
        "",
        "HotSearch(limit: 20, distinct_on: word) {    createTime    id    timesCited    word  }"
      );
      for (let i = 0; i < info.data.HotSearch.length; i++) {
        this.hotSearchWord.push(info.data.HotSearch[i].word);
      }
    },
    //显示推荐的文章内容
    async searchInfo(limit, offest) {
      let info = await QUERYED(
        "post",
        "",
        "  Article(where: {isRecommend: {_eq: 1}},limit: " +
          limit +
          ", offset: " +
          offest +
          ") {  coverImg id userid  imge createTime        commentNum    collectNum        authorName        img      intro    keyword    praiseNum          title      hotNum     }  Article_aggregate(where: {isRecommend: {_eq: 1}}) {  aggregate {    count  }   }"
      );

      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        // alert(info.data.Article[i].coverImg.indexOf('http://'))
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: formatDate(Date.parse(info.data.Article[i].createTime)),
          collectNum: info.data.Article[i].collectNum,
          commentNum: info.data.Article[i].commentNum,
          image:
            info.data.Article[i].coverImg.indexOf("https://") != -1 ||
            info.data.Article[i].coverImg.indexOf("http://") != -1
              ? info.data.Article[i].coverImg
              : this.api.LoginURL.concat(info.data.Article[i].coverImg),

          // image: this.api.LoginURL.concat(info.data.Article[i].coverImg),
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          keyword: info.data.Article[i].keyword,
          imge: this.api.LoginURL.concat(info.data.Article[i].imge),
          userid: info.data.Article[i].userid
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;
    },
    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    }
  },
  components: {
    "V-ArticleList": ArticleList,
    "V-author": Autho,
    "V-OneRelationArticle": OneRelationArticle,
    "V-HotSearchWord": HotSearchWord
    // "V-HotLabel": HotLabel,
  }
};
</script>

<style scoped>
@import "~@/styles/page/index.css";
.container {
  width: 100%;
  /* border: 1px solid firebrick; */
  text-align: center;
  background-color: #f7f7f7;
}
.container > .center {
  margin-top: 30px;
  width: 80%;
  /* border: 1px solid forestgreen; */
  padding-bottom: 30px;
  margin: auto;
}

.container > .center > .content {
  width: 100%;
  /* border: 1px solid red; */
  display: inline-block;
  text-align: center;
  position: relative;
}
.container > .center > .content > .left {
  display: inline-block;
  width: 72%;
  /* border: 1px solid darkblue; */
  background-color: #ffffff;
}
.right {
  /* display: inline-block; */
  width: 90%;
  vertical-align: top;
  text-align: left;
  margin: auto;
  /* margin-left: 30px; */
}
.container > .center > .content > .right > div {
  display: inline-block;
}
.page-nav {
  width: 99%;
}
#author,
#topic {
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  margin-bottom: 10px;
  line-height: 40px;
  /* border-bottom: 1px solid #c0c0c0; */
  /* padding: 10px; */
  /* background-color: rgb(255, 255, 255); */
}
.right-content {
  display: inline-block;
  width: 27%;
  vertical-align: top;
  margin-left: 1%;
  background-color: rgb(255, 255, 255);
  /* border: 1px solid slateblue; */
}
.grid {
  width: 100%;
  font-weight: bold;
  font-size: 17px;
  padding: 12px;
  border-bottom: 1px solid #c0c0c0;
}
.right_b,
.right_c {
  background-color: white;
}
.right_c {
  padding: 10px;
  margin-top: 50px;
  width: 94%;
}
.right_b_left2 {
  border-top: 1px dashed #c0c0c0;
  height: 100px;
  padding: 5px;
}
.right3 {
  width: 100%;
  display: inline-block;
  /* border: 1px solid red; */
}

.flash-bin {
  transition: 0.5s;
  transform: rotate(360deg);
  display: inline-block;
}
.change2 {
  /* display: inline-block;
  text-align: right; */
  float: right;
}
</style>
